import React, { FC } from 'react';

import { Container } from 'layout';
import Typography from 'components/elements/Typography';
import PopularArticles from 'components/sections/PopularArticles';

import { IPropsSearchPopularArticles } from './models';

import './SearchPopularArticles.scss';

const SearchPopularArticles: FC<IPropsSearchPopularArticles> = ({
  popularArticlesByLink,
  popularArticlesByTag,
  popularArticles,
  queryUrlParam,
  titlePopularArticlesInitial,
  siteSettings,
}) => {
  if (
    popularArticles?.length &&
    !popularArticlesByLink?.length &&
    !popularArticlesByTag?.length &&
    popularArticles[0]?.title &&
    queryUrlParam
  ) {
    return (
      <Container>
        <Typography
          data={popularArticles[0]?.title}
          customClass="search-popular-articles__no-results-title"
        />
      </Container>
    );
  }

  return popularArticlesByLink?.length || popularArticlesByTag?.length ? (
    <PopularArticles
      popularArticlesByLink={popularArticlesByLink}
      popularArticlesByTag={popularArticlesByTag}
      data={[
        {
          ...popularArticles[0],
          title:
            !queryUrlParam && titlePopularArticlesInitial?.length
              ? titlePopularArticlesInitial
              : popularArticles[0].title,
        },
      ]}
      globalData={{
        ariaLabelNext: siteSettings.ariaLabelNext,
        ariaLabelPrev: siteSettings.ariaLabelPrev,
        dir: siteSettings.dir,
      }}
      withoutContainerRightPartDesktop={false}
      withoutContainerRightPartMobile={false}
      classNameBaseSection="search-popular-articles"
      data-testid="SearchPopularArticles"
    />
  ) : null;
};

export default SearchPopularArticles;
