import React, { FC, lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { Container } from 'layout';
import Layout from 'layout/Layout';
import AlgoliaSearchBox from 'components/algolia/AlgoliaSearchBox';
import { ALGOLIA_URL_PARAMS } from 'components/algolia/commonHelpers';
import PageIntro from 'components/elements/PageIntro';
import Typography from 'components/elements/Typography';
import StickyWrapper from 'components/helpers/StickyWrapper';
import RecommendedArticles from 'components/sections/RecommendedArticles';
import SearchPopularArticles from 'components/sections/SearchPopularArticles';
import { getLocationQueryStringParam } from 'utils/browser';
import calculateLimitInListing from 'utils/listing';

import helpers from './helpers';
import { IPropsSearchPage } from './models';

import './SearchPage.scss';

const SearchListLoadable = lazy(() => import('components/sections/SearchList'));
const renderLoader = () => null;

const SearchPage: FC<IPropsSearchPage> = ({
  data: {
    pageData,
    siteSettings,
    popularArticlesByLink,
    popularArticlesByTag,
    recommendedArticlesByLink,
    recommendedArticlesByTag,
  },
  pageContext,
}) => {
  const {
    seo,
    urls,
    createDate,
    updateDate,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    searchTitle,
    searchIconAriaLabel,
    searchInputTitle,
    searchTitleWithResults,
    searchTitleWithOneResult,
    searchTitleWithoutResults,
    resetIconAriaLabel,
    withResetButton,
    cardTextPreviewTemplate,
    cardTitleTemplate,
    popularArticles,
    titlePopularArticlesInitial,
    recommendedArticles,
    listingSettings,
    showMoreBtn,
    loadingText,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [queryToDisplay, setQueryToDisplay] = useState<string>('');

  const { isInit, isDesktop } = useScreenRecognition();

  const queryUrlParam = getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch);
  const isSmallDevice = !isDesktop;
  const limit = calculateLimitInListing(isSmallDevice, isInit, listingSettings);

  const {
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
    paginatedItems,
    pageCount,
    handleActiveListPage,
    activeListPage,
    itemsTotal,
  } = useAlgoliaResponse({
    defaultItems: null,
    limitOnPage: limit,
    isSortByOrder: false,
    isLoadMoreMode: true,
  });

  useEffect(() => {
    setQueryToDisplay(queryUrlParam?.[0] || '');
  }, [queryUrlParam]);

  const saveQueryToDisplay = useCallback((value: string) => {
    setQueryToDisplay(value);
  }, []);

  const handleLoadingStatus = useCallback((value: boolean) => {
    setLoading(value);
  }, []);

  return (
    <Layout
      data-testid="SearchPage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="search-page"
    >
      <PageIntro
        breadcrumbs={breadcrumbs}
        showBreadcrumbsDesktop={showBreadcrumbsDesktop}
        showBreadcrumbsMobile={showBreadcrumbsMobile}
      />

      <Container className="search-page__main-container">
        <Typography
          data={[
            {
              ...searchTitle[0],
              text: helpers.getSearchTitle({
                title: searchTitle[0].text,
                searchTitleWithoutResults,
                searchTitleWithResults,
                searchTitleWithOneResult,
                queryToDisplay,
                resultsNumber: itemsTotal || 0,
              }),
            },
          ]}
          customClass="search-page__title"
        />

        <div className="search-page__wrapper">
          <div className="search-page__content">
            <AlgoliaSearchBox
              indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-search`}
              searchIconAriaLabel={searchIconAriaLabel}
              resetIconAriaLabel={resetIconAriaLabel}
              lang={lang}
              handleLoadingStatus={handleLoadingStatus}
              isLoading={isLoading}
              searchInputTitle={searchInputTitle}
              saveQueryToDisplay={saveQueryToDisplay}
              withResetButton={withResetButton}
              itemsTotal={itemsTotal}
              saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
              handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
              handleHitsResponseActivated={handleHitsResponseActivated}
              isHitsResponseActivated={isHitsResponseActivated}
            />

            {queryUrlParam && !isLoading && itemsTotal ? (
              <Suspense fallback={renderLoader()}>
                <SearchListLoadable
                  items={paginatedItems}
                  cardSettings={{ cardTextPreviewTemplate, cardTitleTemplate }}
                  showMoreBtn={showMoreBtn}
                  handleActiveListPage={handleActiveListPage}
                  activeListPage={activeListPage}
                  pageCount={pageCount}
                />
              </Suspense>
            ) : null}
          </div>

          {itemsTotal ? (
            <StickyWrapper
              className="search-page__sticky"
              isSetSticky={!isSmallDevice}
              updateDependencies={activeListPage}
            >
              <div className="search-page__sidebar">
                {recommendedArticlesByLink?.nodes[0] || recommendedArticlesByTag?.nodes[0] ? (
                  <RecommendedArticles
                    data={recommendedArticles}
                    recommendedArticlesByLink={recommendedArticlesByLink?.nodes}
                    recommendedArticlesByTag={recommendedArticlesByTag?.nodes}
                  />
                ) : null}
              </div>
            </StickyWrapper>
          ) : null}
        </div>
      </Container>

      {!queryUrlParam ? (
        <SearchPopularArticles
          queryUrlParam={queryUrlParam}
          siteSettings={siteSettings}
          popularArticles={popularArticles}
          popularArticlesByLink={popularArticlesByLink?.nodes}
          popularArticlesByTag={popularArticlesByTag?.nodes}
          titlePopularArticlesInitial={titlePopularArticlesInitial}
        />
      ) : null}

      {queryUrlParam && isLoading && loadingText?.length ? (
        <div className="search-page__loading-text-wrapper">
          <Typography data={loadingText} />
        </div>
      ) : null}

      {queryUrlParam && !isLoading && !itemsTotal ? (
        <SearchPopularArticles
          queryUrlParam={queryUrlParam}
          siteSettings={siteSettings}
          popularArticles={popularArticles}
          popularArticlesByLink={popularArticlesByLink?.nodes}
          popularArticlesByTag={popularArticlesByTag?.nodes}
          titlePopularArticlesInitial={titlePopularArticlesInitial}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query (
    $url: String!
    $lang: String!
    $popularArticlesLinks: [String]
    $popularArticlesTag: Int
    $recommendedArticlesLinks: [String]
    $recommendedArticlesTag: Int
  ) {
    pageData: searchPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentSearchPage
    }
    siteSettings: siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    popularArticlesByLink: allArticlePage(
      filter: { url: { in: $popularArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    popularArticlesByTag: allArticlePage(
      filter: { tags: { elemMatch: { id: { eq: $popularArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    recommendedArticlesByLink: allArticlePage(
      filter: { url: { in: $recommendedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    recommendedArticlesByTag: allArticlePage(
      filter: { tags: { elemMatch: { id: { eq: $recommendedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
  }
`;

export default SearchPage;
