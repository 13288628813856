import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { Algolia } from '@shared/types';
import algoliasearch from 'algoliasearch/lite';
import classNames from 'classnames';

import AlgoliaCustomHits from 'components/algolia/AlgoliaCustomHits';
import { HitsPerPage } from 'components/algolia/commonHelpers';
import ConnectedCustomSearchBox from 'components/algolia/CustomSearchBox';

import { DefaultSearchBoxState } from './constants';
import helpers from './helpers';
import { IPropsAlgoliaSearchBox } from './models';

import './AlgoliaSearchBox.scss';

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_PUBLIC_KEY as string
);

/**
 * To prevent searches from happening in certain use cases,
 * we need to wrap a proxy around the Algolia search client.
 *
 * This proxy allows us to perform logic before calling the search method
 */
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const AlgoliaSearchBox: FC<IPropsAlgoliaSearchBox> = ({
  indexName,
  lang,
  handleLoadingStatus,
  isLoading,
  searchIconAriaLabel,
  resetIconAriaLabel,
  searchInputTitle,
  saveQueryToDisplay,
  saveAlgoliaHitsResponse,
  handleAlgoliaFiltersUsed,
  handleHitsResponseActivated,
  isHitsResponseActivated,
  itemsTotal,
}): ReactElement | null => {
  const [searchState, setSearchState] = useState<Algolia.ISearchState>(DefaultSearchBoxState);

  const savedQuery = helpers.getSavedQueryToSet() || [''];

  const handleActiveFiltersIdsOnLoad = useCallback((query: string) => {
    handleAlgoliaFiltersUsed(true);
    setSearchState({ ...DefaultSearchBoxState, query });
  }, []);

  useEffect(() => {
    if (savedQuery?.[0]) {
      handleActiveFiltersIdsOnLoad(savedQuery[0]);
    }
  }, [savedQuery?.[0]]);

  useEffect(() => {
    if (!searchState.query) {
      handleAlgoliaFiltersUsed(false);
    } else {
      handleAlgoliaFiltersUsed(true);
    }

    saveQueryToDisplay(searchState?.query || '');
  }, [searchState.query]);

  useEffect(() => {
    if (!itemsTotal) {
      return;
    }

    handleHitsResponseActivated(true);
  }, [itemsTotal]);

  const handleOnSearchStateChange = useCallback((newSearchState: Algolia.ISearchState) => {
    setSearchState(newSearchState);
  }, []);

  const handleSetSearchUrlParam = useCallback((queryToSave: string) => {
    helpers.setSearchUrlParam(queryToSave);
  }, []);

  return (
    <div
      data-testid="AlgoliaSearchBox"
      className={classNames('algolia-search-box', {
        loading: isLoading,
      })}
    >
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={handleOnSearchStateChange}
      >
        <Configure
          filters={helpers.getDefaultFiltersParams(lang)}
          hitsPerPage={HitsPerPage}
          analytics={false}
          distinct
          maxValuesPerFacet={HitsPerPage}
        />

        <ConnectedCustomSearchBox
          /* @ts-ignore */
          handleLoadingStatus={handleLoadingStatus}
          searchIconAriaLabel={searchIconAriaLabel}
          handleSetSearchUrlParam={handleSetSearchUrlParam}
          resetIconAriaLabel={resetIconAriaLabel}
          savedQuery={searchState.query}
          searchInputTitle={searchInputTitle}
          withResetButton
        />

        <AlgoliaCustomHits
          /* @ts-ignore */
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
        />
      </InstantSearch>
    </div>
  );
};

export default AlgoliaSearchBox;
