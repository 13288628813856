import React, { FC, useRef } from 'react';

import { IPropsStickyWrapper } from './models';
import useSticky from 'hooks/useSticky';
import classNames from 'classnames';

const StickyWrapper: FC<IPropsStickyWrapper> = ({
  children,
  topOffset = 115,
  breakpointToInit,
  className,
  updateDependencies,
  isSetSticky,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { isSticky } = useSticky({
    wrapperRef,
    topOffset,
    breakpointToInit,
    updateDependencies,
  });

  return (
    <div
      data-testid="StickyWrapper"
      className={classNames('sticky-wrapper', className, {
        'sticky-wrapper__active': isSetSticky ? isSticky : false,
      })}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
};
export default StickyWrapper;
