import { useEffect, useState } from 'react';

import { getDOMRectById } from 'utils/browser';
import { HTML_ELEMENTS, TABLET_BREAKPOINT } from 'utils/constants';

import { IPropsUseSticky, IResultsUseSticky } from './models';

const useSticky = ({
  wrapperRef,
  topOffset,
  breakpointToInit,
  updateDependencies,
}: IPropsUseSticky): IResultsUseSticky => {
  const [isSticky, setSticky] = useState(false);
  let stickyOffset = topOffset;

  const handleScroll = () => {
    if (
      wrapperRef?.current?.children?.[0] &&
      window.innerWidth >= (breakpointToInit || TABLET_BREAKPOINT)
    ) {
      const curElement = wrapperRef.current.children[0] as HTMLElement;
      const parentValues = wrapperRef.current.getBoundingClientRect();
      const elementValues = wrapperRef.current.children[0].getBoundingClientRect();
      const viewportHeight = window.innerHeight - stickyOffset;
      const addScrolling = viewportHeight <= elementValues.height;
      const startSticky = parentValues.top <= stickyOffset;
      const endSticky =
        parentValues.top - elementValues.height > -parentValues.height + stickyOffset;

      if (startSticky && !endSticky) {
        if (isSticky) {
          curElement.style.position = 'absolute';
          curElement.style.bottom = '10px';
          curElement.style.top = 'auto';
          setSticky(false);
        }
      }

      if (startSticky && endSticky) {
        if (!isSticky) {
          curElement.style.position = 'fixed';
          curElement.style.bottom = '';
          curElement.style.height = addScrolling ? `${viewportHeight}px` : 'auto';
          curElement.style.overflowY = 'scroll';
          curElement.style.top = `${stickyOffset}px`;
          setSticky(true);
        }
      }

      if (!startSticky && endSticky) {
        if (isSticky) {
          curElement.style.position = '';
          curElement.style.bottom = '';
          curElement.style.height = 'auto';
          curElement.style.overflowY = 'unset';
          curElement.style.top = '';
          setSticky(false);
        }
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= (breakpointToInit || TABLET_BREAKPOINT)) {
      if (wrapperRef && wrapperRef.current) {
        const curElement = wrapperRef.current.children[0] as HTMLElement;
        curElement.style.position = '';
        curElement.style.bottom = '';
        curElement.style.top = '';
      }
    }
  };

  useEffect(() => {
    const header = getDOMRectById(HTML_ELEMENTS.HEADER_ID);
    stickyOffset = header ? header.height + 10 : topOffset;
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
      window.removeEventListener('resize', () => handleResize);
    };
  }, [handleScroll]);

  useEffect(() => {
    handleScroll();
  }, [updateDependencies]);

  return { isSticky };
};

export default useSticky;
