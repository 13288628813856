import { ALGOLIA_URL_PARAMS } from 'components/algolia/commonHelpers';
import {
  generateLocationQueryString,
  getLocationQueryStringParam,
  getRemovedParamFromLocationQueryString,
  setUrl,
} from 'utils/browser';

const getDefaultFiltersParams = (lang: string, additionalFilterParams?: string): string =>
  `lang:${lang}${additionalFilterParams || ''}`;

const getSavedQueryToSet = (): string[] | null =>
  getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch);

const setSearchUrlParam = (queryToSave: string): void => {
  let url: string | null = generateLocationQueryString(ALGOLIA_URL_PARAMS.querySearch, queryToSave);

  if (!url || !queryToSave) {
    url = getRemovedParamFromLocationQueryString(ALGOLIA_URL_PARAMS.querySearch);
  }

  setUrl(url || '');
};

const helpers = {
  getDefaultFiltersParams,
  getSavedQueryToSet,
  setSearchUrlParam,
};

export default helpers;
