import React, { FC } from 'react';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Container from 'layout/Container';
import BreadCrumbs from 'components/elements/BreadCrumbs';

import { IPropsPageIntro } from './models';

import './PageIntro.scss';

const PageIntro: FC<IPropsPageIntro> = ({
  breadcrumbs,
  showBreadcrumbsDesktop,
  showBreadcrumbsMobile,
}) => {
  const { isMobile } = useScreenRecognition();

  if (isMobile && !showBreadcrumbsMobile) {
    return null;
  }

  if (!isMobile && !showBreadcrumbsDesktop) {
    return null;
  }

  return (
    <Container className="page-intro" data-testid="PageIntro">
      <BreadCrumbs breadcrumbs={breadcrumbs} />
    </Container>
  );
};
export default PageIntro;
