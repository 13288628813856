import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Button from 'components/elements/Button';
import Typography from 'components/elements/Typography';
import DynamicImage from 'components/helpers/DynamicImage';

import { MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH } from './constants';
import { IPropsArticleCard } from './models';

import './ArticleCard.scss';

const ArticleCard: FC<IPropsArticleCard> = ({
  data,
  url,
  variant = 'default',
  cta,
  customClass,
  cardSettings,
}) => (
  <article
    data-testid="ArticleCard"
    className={classNames('article-card', customClass, `article-card--variant-${variant}`)}
  >
    <Link to={url} className="article-card__link" aria-label={data.title[0].text}>
      <div className="article-card__image">
        <DynamicImage image={data.previewImage} />
      </div>
      <div className="article-card__content">
        <Typography
          customClass="article-card__title"
          data={data.title}
          truncateToLength={cardSettings[0].cardTitleLength || MAX_TITLE_LENGTH}
        />
        {variant === 'default' ? (
          <Typography
            customClass="article-card__description"
            data={data.description}
            truncateToLength={cardSettings?.[0]?.cardDescriptionLength || MAX_DESCRIPTION_LENGTH}
          />
        ) : null}
        <Button
          data={[
            {
              ...cta[0],
              link: [{ name: data.title[0].text, url }],
            },
          ]}
        />
      </div>
    </Link>
  </article>
);
export default ArticleCard;
