import React, {
  ChangeEvent,
  FC,
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import classNames from 'classnames';

import IconCustom from 'components/helpers/IconCustom';

import { IPropsCustomSearchBox } from './models';

import './CustomSearchBox.scss';

export const CustomSearchBox: FC<IPropsCustomSearchBox> = ({
  searchIconAriaLabel,
  refine,
  handleSetSearchUrlParam,
  resetIconAriaLabel,
  savedQuery,
  isSearchStalled,
  handleLoadingStatus,
  searchInputTitle,
  withResetButton,
}): ReactElement | null => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setTimeout(() => handleLoadingStatus(isSearchStalled), 200);
  }, [isSearchStalled]);

  useEffect(() => {
    setInputValue(savedQuery || '');
  }, [savedQuery]);

  const handleValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
    []
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const cleanValue = inputValue.trim();
      setInputValue(cleanValue);
      refine(cleanValue);
      handleSetSearchUrlParam(cleanValue);
    },
    [inputValue]
  );

  const handleReset = useCallback(() => {
    refine('');
    setInputValue('');
    handleSetSearchUrlParam('');
  }, []);

  return (
    <div data-test="CustomSearchBox" className="custom-search-box">
      <form
        noValidate
        className={classNames('custom-search-box__form', {
          'with-reset-button': withResetButton,
        })}
        onSubmit={handleSubmit}
      >
        <input
          title={searchInputTitle}
          aria-label={searchInputTitle}
          type="search"
          value={inputValue}
          onChange={handleValueChange}
          className="custom-search-box__form-input"
        />

        <button
          aria-label={searchIconAriaLabel}
          type="submit"
          className="custom-search-box__search-button"
        >
          <IconCustom icon="search" className="custom-search-box__search-icon" />
        </button>
        {withResetButton && inputValue ? (
          <button
            className="custom-search-box__reset-button"
            type="button"
            onClick={handleReset}
            aria-label={resetIconAriaLabel}
          >
            <IconCustom icon="exit" className="custom-search-box__reset-icon" />
          </button>
        ) : null}
      </form>
    </div>
  );
};

// @ts-ignore
const ConnectedCustomSearchBox = connectSearchBox<IPropsCustomSearchBox>(CustomSearchBox);

export default ConnectedCustomSearchBox;
