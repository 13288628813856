import React, { FC, useMemo } from 'react';
import { UmbracoArticleCard } from '@shared/types';

import ArticleCard from 'components/cards/ArticleCard';
import Button from 'components/elements/Button';
import Typography from 'components/elements/Typography';
import { sortByOrderUrls } from 'utils/sorting';

import { IPropsRecommendedArticles } from './models';

import './RecommendedArticles.scss';

const RecommendedArticles: FC<IPropsRecommendedArticles> = ({
  data: [data],
  recommendedArticlesByLink,
  recommendedArticlesByTag,
}) => {
  const sortArticlesByIndex = useMemo(
    () => sortByOrderUrls(recommendedArticlesByLink, data.selectedArticlesLinks),
    [data, recommendedArticlesByLink]
  );

  const itemsToRender =
    data?.selectedArticlesMode === 'manual' && recommendedArticlesByLink?.length
      ? sortArticlesByIndex
      : recommendedArticlesByTag;

  return (
    <div data-testid="RecommendedArticles" className="recommended-articles">
      <Typography data={data.title} customClass="recommended-articles__title" />
      {itemsToRender?.map((article: UmbracoArticleCard.IStructure) => (
        <ArticleCard
          key={article.url}
          data={article.previewData[0]}
          url={article.url}
          cta={data.ctaCard}
          cardSettings={data.articleCardsSettings}
          variant="recommended"
        />
      ))}
      {data.ctaLink?.length ? (
        <Button data={data.ctaLink} className="recommended-articles__list-cta" />
      ) : null}
    </div>
  );
};
export default RecommendedArticles;
